import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import ContainerSpinner from '@hsl-fi/container-spinner'
import Page from '../../../common/components/Page'
import apiStore from '../../../common/stores/apiStore'
import uiMessageStore from '../../../common/stores/uiMessageStore'
import WhiteBox from '../../../common/components/WhiteBox'
import { H1, H2, P, Ul } from '../../../common/components/Typography'
import { ExternalLinkButton } from '../../../common/components/Button'
import { Redirect } from '../../routes/components/Link'
import ROUTES from '../../routes/routes'
import { BENEFICIARY_LOGIN_URL } from '../../anonymous/constants'
import { INVITE_STATUS } from '../../company/constants'
import { BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE } from '../../../common/constants'

const RegistrationScreen = () => {
  const [t, i18n] = useTranslation()
  const [searchParams] = useSearchParams()
  const inviteId = searchParams.get('invite_id')
  const [state, setState] = useState({
    isBusy: true,
    status: undefined,
    benefitType: undefined,
  })

  useEffect(() => {
    const validateInvite = async () => {
      try {
        const { status, benefitType } =
          await apiStore.endpoints.invite.validateBeneficiaryInvite(inviteId, {
            credentials: 'omit',
          })

        setState({ status, benefitType, isBusy: false })
      } catch (e) {
        Sentry.captureEvent({
          message: 'Beneficiary invite validation failed',
          extra: {
            error: e,
          },
        })

        uiMessageStore.addError(t('COMMON:ERRORS.SERVER_ERROR'), e)

        setState((prevState) => ({ ...prevState, isBusy: false }))
      }
    }

    validateInvite()
  }, [location.search, t])

  if (state.isBusy) {
    return <ContainerSpinner visible={true} />
  }

  const isInviteValid = state.status === INVITE_STATUS.VALID

  if (!state.isBusy && !isInviteValid) {
    return (
      <Redirect
        route={ROUTES.BENEFICIARY.INVALID_LINK}
        query={{ status: state.status }}
      />
    )
  }

  const isBusinessTrip =
    state.benefitType === BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE

  return (
    <Page>
      <WhiteBox>
        <H1>
          {isBusinessTrip
            ? t('BENEFICIARY:REGISTRATION_SCREEN.BUSINESS_TRIP.INTRO_TITLE')
            : t('BENEFICIARY:REGISTRATION_SCREEN.COMMUTER_BENEFIT.INTRO_TITLE')}
        </H1>
        <P>
          {isBusinessTrip
            ? t(
                'BENEFICIARY:REGISTRATION_SCREEN.BUSINESS_TRIP.INTRO_DESCRIPTION_1'
              )
            : t(
                'BENEFICIARY:REGISTRATION_SCREEN.COMMUTER_BENEFIT.INTRO_DESCRIPTION_1'
              )}
        </P>
        <P>
          {isBusinessTrip
            ? t(
                'BENEFICIARY:REGISTRATION_SCREEN.BUSINESS_TRIP.INTRO_DESCRIPTION_2'
              )
            : t(
                'BENEFICIARY:REGISTRATION_SCREEN.COMMUTER_BENEFIT.INTRO_DESCRIPTION_2'
              )}
        </P>
        {!isBusinessTrip && (
          <P>
            {t(
              'BENEFICIARY:REGISTRATION_SCREEN.COMMUTER_BENEFIT.INTRO_DESCRIPTION_3'
            )}
          </P>
        )}
        <H2>
          {t('BENEFICIARY:REGISTRATION_SCREEN.INTRO_BULLET_POINTS_TITLE')}
        </H2>
        <Ul
          items={[
            t('BENEFICIARY:REGISTRATION_SCREEN.INTRO_BULLET_POINT_1'),
            t('BENEFICIARY:REGISTRATION_SCREEN.INTRO_BULLET_POINT_2'),
            t('BENEFICIARY:REGISTRATION_SCREEN.INTRO_BULLET_POINT_3'),
            t('BENEFICIARY:REGISTRATION_SCREEN.INTRO_BULLET_POINT_4'),
            t('BENEFICIARY:REGISTRATION_SCREEN.INTRO_BULLET_POINT_5'),
          ]}
          bulletColor="blue"
          style={{
            marginBottom: '30px',
          }}
        />
        <ExternalLinkButton
          href={`${BENEFICIARY_LOGIN_URL}?ui_locales=${i18n.language}&email=&redirectTo=auth-success?inviteId=${inviteId}`}
        >
          {t('BENEFICIARY:REGISTRATION_SCREEN.CONTINUE_TO_LOGIN')}
        </ExternalLinkButton>
      </WhiteBox>
    </Page>
  )
}

export default RegistrationScreen
