import { makeAutoObservable } from 'mobx'
import { makePersistable } from 'mobx-persist-store'
import { ELECTRONIC_INVOICE } from '../../../common/components/BillingMethodInput'

const blankForm = {
  companyName: '',
  businessId: '',
  numberOfEmployees: undefined,
  officeStreetAddress: '',
  officePostalCode: '',
  officeCity: '',
  useOfficeAsMailingAddress: true,
  mailingStreetAddress: '',
  mailingPostalCode: '',
  mailingCity: '',
  billingMethod: ELECTRONIC_INVOICE,
  billingIntermediary: '',
  electronicInvoiceAddress: '',
  contactName: '',
  contactPhoneNumber: '+358',
  contactWorkEmail: '',
  termsOfService: false,
  privacyPolicy: false,
  companyPermission: false,
}

class ApplicationFormStore {
  form = blankForm

  constructor() {
    makeAutoObservable(this)

    makePersistable(this, {
      name: 'ApplicationFormStore',
      properties: ['form'],
      storage: window.localStorage,
    })
  }

  setValues = (values) => {
    this.form = {
      ...this.form,
      ...values,
    }
  }

  resetForm = () => {
    this.setValues(blankForm)
  }
}

export default new ApplicationFormStore()
