import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { Suspense, lazy } from 'react'
import { withTranslation } from 'react-i18next'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ErrorBoundary from '../common/components/ErrorBoundary'
import ScrollToTop from '../common/components/ScrollToTop'
import { LANGUAGES } from '../common/constants'
import CustomQueryClientProvider from './company/components/CustomQueryClientProvider/CustomQueryClientProvider'
import {
  BasicRouteRenderer,
  RedirectToHome,
  RedirectWithParamsAndQuery,
  basicRoute,
  companyAdminRoute,
  redirect,
  supervisorRoute,
} from './routes/components/Routes'
import ROUTES from './routes/routes'

// Anonymous
import Spinner from '../common/components/Spinner'
import ErrorScreen from './anonymous/components/ErrorScreen'
import HomeScreen from './anonymous/components/HomeScreen'
import LoginSuccess from './anonymous/components/LoginSuccess'
import LoginUnavailableScreen from './anonymous/components/LoginUnavailableScreen'
import NotFoundScreen from './anonymous/components/NotFoundScreen'
import TestScreen from './anonymous/components/TestScreen'
import TestWithParamScreen from './anonymous/components/TestWithParamScreen'
import UnauthorizedScreen from './anonymous/components/UnauthorizedScreen'

// Company admin
const SelectCompanyScreen = lazy(() =>
  import('./company/components/SelectCompanyScreen')
)
const CompanyPortalScreen = lazy(() =>
  import('./company/components/CompanyPortalScreen/CompanyPortalScreen')
)
const AddBeneficiariesScreen = lazy(() =>
  import('./company/components/Benefits/AddBeneficiariesScreen')
)
const TicketOrdersScreen = lazy(() =>
  import('./company/components/TicketOrdersScreen')
)
const ReportsSummaryScreen = lazy(() =>
  import('./company/components/ReportsSummaryScreen')
)
const ReportsExportsScreen = lazy(() =>
  import('./company/components/ReportsExportsScreen')
)
const MobileTicketOrderScreen = lazy(() =>
  import('./company/components/MobileTicketOrderScreen')
)
const PaperCardTicketOrderScreen = lazy(() =>
  import('./company/components/PaperCardTicketOrderScreen')
)
const BusinessTravelBeneficiariesScreen = lazy(() =>
  import(
    './company/components/Benefits/BeneficiariesScreen/Employee/BusinessTravelBeneficiariesScreen'
  )
)
const CommuterBeneficiariesScreen = lazy(() =>
  import(
    './company/components/Benefits/BeneficiariesScreen/Employee/CommuterBeneficiariesScreen'
  )
)
const BenefitSettingsScreen = lazy(() =>
  import('./company/components/BenefitSettingsScreen')
)
const SingleBeneficiaryScreen = lazy(() =>
  import(
    './company/components/Benefits/SingleBeneficiaryScreen/SingleBeneficiaryScreen'
  )
)
const AboutCompanyScreen = lazy(() =>
  import('./company/components/AboutCompanyScreen')
)
const EventLogScreen = lazy(() => import('./company/components/EventLogScreen'))
const InstructionsScreen = lazy(() =>
  import('./company/components/InstructionsScreen')
)
const MyProfileScreen = lazy(() =>
  import('./company/components/MyProfileScreen')
)
const ReviewTermsOfServiceScreen = lazy(() =>
  import('./company/components/ReviewTermsOfServiceScreen')
)

//School tickets
const AddSchoolBeneficiariesScreen = lazy(() =>
  import(
    './company/components/Benefits/SchoolBeneficiaries/AddSchoolBeneficiariesScreen'
  )
)
const SchoolBeneficiariesScreen = lazy(() =>
  import(
    './company/components/Benefits/BeneficiariesScreen/School/SchoolBeneficiariesScreen'
  )
)
const SeasonView = lazy(() =>
  import(
    './company/components/Benefits/SchoolBeneficiaries/SeasonsView/SeasonsView'
  )
)

const CreateOrEditSeasonView = lazy(() =>
  import(
    './company/components/Benefits/SchoolBeneficiaries/SeasonsView/CreateOrEditSeasonView/CreateOrEditSeasonView'
  )
)

const SchoolBeneficiaryReports = lazy(() =>
  import(
    './company/components/Benefits/SchoolBeneficiaries/SchoolBeneficiaryReports/SchoolBeneficiaryReports.js'
  )
)
const SingleSchoolBeneficiaryView = lazy(() =>
  import(
    './company/components/Benefits/SchoolBeneficiaries/SingleSchoolBeneficiaryView/SingleSchoolBeneficiaryView'
  )
)
// Supervisor (HSL admin)
import SupervisorLandingScreen from './supervisor/components/SupervisorLandingScreen'

const CompaniesScreen = lazy(() =>
  import('./supervisor/components/CompaniesScreen')
)
const EventsScreen = lazy(() => import('./supervisor/components/EventsScreen'))
const SingleCompanyScreen = lazy(() =>
  import('./supervisor/components/SingleCompanyScreen')
)
const AddNewCompanyScreen = lazy(() =>
  import('./supervisor/components/AddNewCompanyScreen')
)
const ExportsScreen = lazy(() =>
  import('./supervisor/components/ExportsScreen')
)

// Beneficiary flow
import AuthSuccessScreen from './beneficiary/components/AuthSuccessScreen'
import RegistrationScreen from './beneficiary/components/RegistrationScreen'
import VerificationScreen from './beneficiary/components/VerificationScreen'

import BeneficiarySuccessScreen from './beneficiary/components/BeneficiarySuccessScreen'
import BeneficiarySuccessScreenMock from './beneficiary/components/BeneficiarySuccessScreenMock'
import InvalidLinkScreen from './beneficiary/components/InvalidLinkScreen'
import StrongAuthenticationErrorScreen from './beneficiary/components/StrongAuthenticationErrorScreen'
import StrongAuthenticationSuccessScreen from './beneficiary/components/StrongAuthenticationSuccessScreen'
import VerificationSuccessScreen from './beneficiary/components/VerificationSuccessScreen'

// Self-service
import ApplicationConfirmationScreen from './selfService/components/ApplicationConfirmationScreen'
import ApplicationScreen from './selfService/components/ApplicationScreen'
import ApplicationSentScreen from './selfService/components/ApplicationSentScreen'
import SelfServiceAuthSuccessScreen from './selfService/components/SelfServiceAuthSuccessScreen'
import StrongAuthenticationScreen from './selfService/components/StrongAuthenticationScreen'

import CompanyAdminVerificationScreen from './selfService/components/CompanyAdminVerificationScreen'
import NewCompanyAdminScreen from './selfService/components/NewCompanyAdminScreen'
import SelfServiceStrongAuthenticationErrorScreen from './selfService/components/StrongAuthenticationErrorScreen'
import SelfServiceStrongAuthenticationSuccessScreen from './selfService/components/StrongAuthenticationSuccessScreen'

const App = () => (
  <CustomQueryClientProvider>
    <BrowserRouter>
      <ErrorBoundary>
        <ScrollToTop />
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/">
              <Route index element={<RedirectToHome />} />
              <Route path="authenticated" element={<LoginSuccess />} />
              <Route
                path="access-denied"
                element={
                  <RedirectWithParamsAndQuery
                    route={ROUTES.AUTHENTICATION.UNAUTHORIZED}
                  />
                }
              />
              <Route
                path="login-unavailable"
                element={
                  <RedirectWithParamsAndQuery
                    route={ROUTES.AUTHENTICATION.SERVICE_UNAVAILABLE}
                  />
                }
              />
              <Route
                path="error"
                element={
                  <RedirectWithParamsAndQuery
                    route={ROUTES.ERROR.GENERIC_ERROR}
                  />
                }
              />
              {/* Beneficiary redirects */}
              <Route
                exact
                path="registration"
                element={
                  <RedirectWithParamsAndQuery
                    route={ROUTES.BENEFICIARY.REGISTRATION}
                  />
                }
              />
              {/* keep supporting old invitation links */}
              <Route
                exact
                path="auth-success"
                element={
                  <RedirectWithParamsAndQuery
                    route={ROUTES.BENEFICIARY.AUTH_SUCCESS}
                  />
                }
              />
              <Route
                exact
                path="strong-authentication-success"
                element={
                  <RedirectWithParamsAndQuery
                    route={ROUTES.BENEFICIARY.STRONG_AUTHENTICATION_SUCCESS}
                  />
                }
              />
              <Route
                exact
                path="strong-authentication-error"
                element={
                  <RedirectWithParamsAndQuery
                    route={ROUTES.BENEFICIARY.STRONG_AUTHENTICATION_ERROR}
                  />
                }
              />
              {/* Self-service redirects */}
              <Route
                exact
                path="self-service"
                element={
                  <RedirectWithParamsAndQuery
                    route={ROUTES.SELF_SERVICE.HOME}
                  />
                }
              />{' '}
              {/* keep supporting the old self-service landing URL */}
              <Route
                exact
                path="self-service/new-company-admin"
                element={
                  <RedirectWithParamsAndQuery
                    route={ROUTES.SELF_SERVICE.NEW_COMPANY_ADMIN}
                  />
                }
              />{' '}
              {/* keep supporting old invitation links */}
              <Route
                exact
                path="self-service/auth-success"
                element={
                  <RedirectWithParamsAndQuery
                    route={ROUTES.SELF_SERVICE.AUTH_SUCCESS}
                  />
                }
              />
              <Route
                exact
                path="self-service/strong-authentication-success"
                element={
                  <RedirectWithParamsAndQuery
                    route={ROUTES.SELF_SERVICE.STRONG_AUTHENTICATION_SUCCESS}
                  />
                }
              />
              <Route
                exact
                path="self-service/strong-authentication-error"
                element={
                  <RedirectWithParamsAndQuery
                    route={ROUTES.SELF_SERVICE.STRONG_AUTHENTICATION_ERROR}
                  />
                }
              />
              <Route
                exact
                path="test"
                element={
                  <RedirectWithParamsAndQuery
                    route={ROUTES.COMPANY_ADMIN.DEV_TEST}
                  />
                }
              />
              {
                // Translated routes
                LANGUAGES.map(({ code }) => (
                  <Route key={code} path={code}>
                    <Route
                      index
                      element={
                        <BasicRouteRenderer
                          component={HomeScreen}
                          absolutePath={`/${code}`}
                          language={code}
                        />
                      }
                    />

                    {/* Anonymous routes */}
                    {basicRoute(
                      ROUTES.AUTHENTICATION.SERVICE_UNAVAILABLE,
                      LoginUnavailableScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.AUTHENTICATION.UNAUTHORIZED,
                      UnauthorizedScreen,
                      code
                    )}
                    {basicRoute(ROUTES.ERROR.GENERIC_ERROR, ErrorScreen, code)}

                    {/* Beneficiary routes */}
                    {basicRoute(
                      ROUTES.BENEFICIARY.REGISTRATION,
                      RegistrationScreen,
                      code
                    )}
                    {/* Beneficiary routes */}
                    {basicRoute(
                      ROUTES.BENEFICIARY.AUTH_SUCCESS,
                      AuthSuccessScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.BENEFICIARY.VERIFICATION,
                      VerificationScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.BENEFICIARY.VERIFICATION_SUCCESS,
                      VerificationSuccessScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.BENEFICIARY.STRONG_AUTHENTICATION_SUCCESS,
                      StrongAuthenticationSuccessScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.BENEFICIARY.STRONG_AUTHENTICATION_ERROR,
                      StrongAuthenticationErrorScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.BENEFICIARY.BENEFICIARY_SUCCESS_SCREEN,
                      BeneficiarySuccessScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.BENEFICIARY.BENEFICIARY_SUCCESS_SCREEN_MOCK,
                      BeneficiarySuccessScreenMock,
                      code
                    )}
                    {basicRoute(
                      ROUTES.BENEFICIARY.INVALID_LINK,
                      InvalidLinkScreen,
                      code
                    )}

                    {/* Company admin routes */}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.COMPANY_PORTAL,
                      CompanyPortalScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.BUSINESS_TRIP__ADD_BENEFICIARIES,
                      AddBeneficiariesScreen,
                      code
                    )}
                    {redirect(
                      ROUTES.COMPANY_ADMIN.BUSINESS_TRIP__ROOT,
                      ROUTES.COMPANY_ADMIN.BUSINESS_TRIP__ADD_BENEFICIARIES,
                      true,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.BUSINESS_TRIP__BENEFICIARIES,
                      BusinessTravelBeneficiariesScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.BUSINESS_TRIP__BENEFICIARY,
                      SingleBeneficiaryScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.BUSINESS_TRIP__BENEFIT_SETTINGS,
                      BenefitSettingsScreen,
                      code
                    )}
                    {redirect(
                      ROUTES.COMPANY_ADMIN.ROOT,
                      ROUTES.COMPANY_ADMIN.ADD_BENEFICIARIES,
                      true,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.SELECT,
                      SelectCompanyScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.REVIEW_TERMS_OF_SERVICE,
                      ReviewTermsOfServiceScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.ADD_BENEFICIARIES,
                      AddBeneficiariesScreen,
                      code
                    )}

                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.TICKET_ORDERS__ROOT,
                      TicketOrdersScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.TICKET_ORDERS__MOBILE_TICKET_ORDER,
                      MobileTicketOrderScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN
                        .TICKET_ORDERS__PAPER_CARD_TICKET_ORDER,
                      PaperCardTicketOrderScreen,
                      code
                    )}
                    {redirect(
                      ROUTES.COMPANY_ADMIN.REPORTS__ROOT,
                      ROUTES.COMPANY_ADMIN.REPORTS__SUMMARY,
                      true,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.REPORTS__SUMMARY,
                      ReportsSummaryScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.REPORTS__EXPORTS,
                      ReportsExportsScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.BENEFICIARIES,
                      CommuterBeneficiariesScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.BENEFIT_SETTINGS,
                      BenefitSettingsScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.BENEFICIARY,
                      SingleBeneficiaryScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.ABOUT,
                      AboutCompanyScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.EVENT_LOG,
                      EventLogScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.INSTRUCTIONS,
                      InstructionsScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.MY_PROFILE,
                      MyProfileScreen,
                      code
                    )}
                    {/* SChool ticket routes */}
                    {redirect(
                      ROUTES.COMPANY_ADMIN.SCHOOL_TICKET_ROOT,
                      ROUTES.COMPANY_ADMIN.SCHOOL_TICKET_ADD_BENEFICIARIES,
                      true,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.SCHOOL_TICKET_ADD_BENEFICIARIES,
                      AddSchoolBeneficiariesScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.SCHOOL_TICKET_BENEFICIARIES,
                      SchoolBeneficiariesScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.SCHOOL_TICKET_SINGLE_BENEFICIARY,
                      SingleSchoolBeneficiaryView,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.SCHOOL_TICKET_SEASONS_VIEW,
                      SeasonView,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.SCHOOL_TICKET_CREATE_SEASON_VIEW,
                      CreateOrEditSeasonView,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.SCHOOL_TICKET_EDIT_SEASON_VIEW,
                      CreateOrEditSeasonView,

                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.SCHOOL_TICKET_REPORTS,
                      SchoolBeneficiaryReports,
                      code
                    )}
                    {/* Supervisor routes */}
                    {basicRoute(
                      ROUTES.SUPERVISOR.LANDING,
                      SupervisorLandingScreen,
                      code
                    )}
                    {supervisorRoute(
                      ROUTES.SUPERVISOR.COMPANIES,
                      CompaniesScreen,
                      code
                    )}
                    {supervisorRoute(
                      ROUTES.SUPERVISOR.COMPANY,
                      SingleCompanyScreen,
                      code
                    )}
                    {supervisorRoute(
                      ROUTES.SUPERVISOR.ADD_NEW_COMPANY,
                      AddNewCompanyScreen,
                      code
                    )}
                    {supervisorRoute(
                      ROUTES.SUPERVISOR.EVENTS,
                      EventsScreen,
                      code
                    )}
                    {supervisorRoute(
                      ROUTES.SUPERVISOR.EXPORTS,
                      ExportsScreen,
                      code
                    )}

                    {/* Self-service routes */}
                    {basicRoute(
                      ROUTES.SELF_SERVICE.HOME,
                      ApplicationScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.SELF_SERVICE.APPLICATION_SENT,
                      ApplicationSentScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.SELF_SERVICE.APPLICATION_CONFIRMATION_SCREEN,
                      ApplicationConfirmationScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.SELF_SERVICE.NEW_COMPANY_ADMIN,
                      NewCompanyAdminScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.SELF_SERVICE.AUTH_SUCCESS,
                      SelfServiceAuthSuccessScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.SELF_SERVICE.VERIFICATION,
                      CompanyAdminVerificationScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.SELF_SERVICE.STRONG_AUTHENTICATION,
                      StrongAuthenticationScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.SELF_SERVICE.STRONG_AUTHENTICATION_SUCCESS,
                      SelfServiceStrongAuthenticationSuccessScreen,
                      code
                    )}
                    {basicRoute(
                      ROUTES.SELF_SERVICE.STRONG_AUTHENTICATION_ERROR,
                      SelfServiceStrongAuthenticationErrorScreen,
                      code
                    )}

                    {/* Test screens */}
                    {basicRoute(
                      ROUTES.COMPANY_ADMIN.DEV_TEST_WITH_PARAM,
                      TestWithParamScreen,
                      code
                    )}
                    {companyAdminRoute(
                      ROUTES.COMPANY_ADMIN.DEV_TEST,
                      TestScreen,
                      code
                    )}
                  </Route>
                ))
              }
            </Route>
            <Route path="*" element={<NotFoundScreen />} />
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
    <ReactQueryDevtools initialIsOpen={false} />
  </CustomQueryClientProvider>
)

export default withTranslation()(App) // withTranslation is required to trigger a re-render on language change
