import i18n from './i18n'
import { AGE_GROUP_CHILD, ENGLISH, EM_DASH, EN_DASH } from './constants'

export const formatNumber = (number, language) => {
  const numberAsString = String(number)

  if (language === ENGLISH.code) {
    return numberAsString
  }

  return numberAsString.replace('.', ',')
}

export const formatMoney = (number, language) => {
  const value = formatNumber(parseFloat(number).toFixed(2), language)

  return language === ENGLISH.code ? `€${value}` : `${value}€`
}
export const formatMoneyPeriod = (number, period = 'YEAR', language) => {
  const value = formatMoney(number, language)
  const key = period === 'YEAR' ? 'COMMON:YEAR' : 'COMMON:MONTH'
  return `${value}/${i18n.t(key)}`
}

export const formatDate = (dateAsString, fallback = EM_DASH, options) =>
  formatDateInternal(dateAsString, fallback, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    ...options,
  })

export const formatDateRange = (
  startDateAsString,
  endDateAsString,
  separator = EN_DASH,
  fallback = EM_DASH,
  options
) =>
  [
    formatDate(startDateAsString, fallback, options),
    separator,
    formatDate(endDateAsString, fallback, options),
  ].join(' ')

const formatDateInternal = (dateAsString, fallback = EM_DASH, options) =>
  isNaN(new Date(dateAsString))
    ? fallback
    : new Date(dateAsString).toLocaleString('fi-FI', options)

export const formatMonthYear = (dateAsString, fallback, options) =>
  formatDateInternal(dateAsString, fallback, {
    year: 'numeric',
    month: 'numeric',
    ...options,
  })

export const formatYear = (dateAsString, fallback, options) =>
  formatDateInternal(dateAsString, fallback, {
    year: 'numeric',
    ...options,
  })

export const formatDateTime = (dateAsString, fallback, options) =>
  formatDateInternal(dateAsString, fallback, {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    ...options,
  })

export const formatAddressOnSingleLine = (streetAddress, postalCode, city) =>
  [streetAddress, [postalCode, city].filter(Boolean).join(' ')]
    .filter(Boolean)
    .join(', ')

export const formatAgeGroup = (ageGroup, t) => {
  if (ageGroup === AGE_GROUP_CHILD) {
    return t('COMMON:CHILD')
  }

  return t('COMMON:ADULT')
}

export const formatTicketValidity = (validity, t) => {
  if (validity === 'SINGLE_TICKET') {
    return t('COMMON:SINGLE_TICKET')
  }

  const [count] = (validity ?? '').split('_')

  return t('COMMON:X_DAY_TICKET', { count: Number(count) })
}
