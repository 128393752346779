import React, { Component, useEffect } from 'react'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import { H1, B, P } from './Typography'
import Page from './Page'
import WhiteBox from './WhiteBox'
import './ErrorBoundary.scss'

class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.node,
  }

  state = {
    error: undefined,
    errorInfo: undefined,
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    })
  }

  render() {
    const { children } = this.props
    const { error, errorInfo } = this.state

    if (errorInfo) {
      return <ErrorScreen error={error} errorInfo={errorInfo} />
    }

    return children
  }
}

const ErrorScreen = ({ error, errorInfo }) => {
  const [t] = useTranslation()

  useEffect(() => {
    Sentry.captureEvent({
      message: 'Caught by ErrorScreen',
      extra: {
        error,
        errorInfo,
      },
    })
  }, [])

  return (
    <Page>
      <WhiteBox>
        <H1>{t('ANONYMOUS:ERROR_BOUNDARY.TITLE')}</H1>
        <details className="common__error-boundary__details">
          <summary className="common__error-boundary__summary">
            {t('ANONYMOUS:ERROR_BOUNDARY.SUMMARY')}
          </summary>
          <div className="common__error-boundary__body">
            <B>{error && error.toString()}</B>
            <P style={{ marginTop: 0 }}>{errorInfo.componentStack}</P>
          </div>
        </details>
      </WhiteBox>
    </Page>
  )
}

ErrorScreen.propTypes = {
  error: PropTypes.object,
  errorInfo: PropTypes.object,
}

export default ErrorBoundary
