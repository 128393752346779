import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { observer } from 'mobx-react'
import ContainerSpinner from '@hsl-fi/container-spinner'
import pushEvent from '../tagManager'
import userStore from '../stores/userStore'
import apiStore from '../stores/apiStore'

const Authenticated = ({ children }) => {

  useEffect(() => {
    const fetchUser = async () => {
      const isAlreadyAuthenticated = Boolean(
        userStore.user && userStore.user.sub
      )

      if (isAlreadyAuthenticated) {
        return
      }

      try {
        const response = await apiStore.fetchUser()

        pushEvent('userData', { userType: response.role })

        userStore.setUser(response)
      } catch (e) {
        userStore.setUser({}, true)
      }
    }

    fetchUser()
  }, [])

  const isAuthenticated = Boolean(userStore.user && userStore.user.sub)

  return (
    <ContainerSpinner visible={!isAuthenticated}>
      {isAuthenticated ? children : null}
    </ContainerSpinner>
  )
}

Authenticated.propTypes = {
  children: PropTypes.node,
}

export default observer(Authenticated)
