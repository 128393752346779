import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './PaddedContent.scss'

const PaddedContent = ({ children, className, style }) => (
  <div
    className={classnames('common__padded-content', className)}
    style={style}
  >
    {children}
  </div>
)

PaddedContent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default PaddedContent
