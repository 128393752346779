import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import HslDropdown from '@hsl-fi/dropdown'
import './Dropdown.scss'

const Dropdown = ({ className, ...props }) => {
  const [t] = useTranslation()

  return (
    <HslDropdown
      openMenuAriaLabel={t('COMMON:OPEN_DROPDOWN')}
      closeMenuAriaLabel={t('COMMON:CLOSE_DROPDOWN')}
      className={classnames('common__dropdown', className)}
      {...props}
    />
  )
}

Dropdown.propTypes = {
  className: PropTypes.string,
}

export default Dropdown
