import './index.scss'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import { createRoot } from 'react-dom/client'
import App from './modules/App'
import './common/sentry'
import './common/i18n'
import './common/mobx'
import './common/commitSha'
import './common/datepicker'

const container = document.getElementById('root')
const root = createRoot(container)
root.render(<App />)
