import { useState } from 'react'

const useOnChange = (onChange, data) => {
  const [prevData, setPrevData] = useState(data)
  if (data !== prevData) {
    setPrevData(data)
    onChange()
  }
}

export default useOnChange
