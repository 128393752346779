import * as Sentry from '@sentry/react'

// Enable Sentry in a deployed Azure environment only (dev/UAT/production)
const SENTRY_DSN =
  process.env.NODE_ENV === 'production'
    ? 'https://38ed0ef295664ad48f66f4ddc4ffde51@o26423.ingest.sentry.io/5503906'
    : undefined

Sentry.init({
  dsn: SENTRY_DSN,
  environment: process.env.REACT_APP_ENV || 'local',
  ignoreErrors: [
    'AbortError',
    'fbq is not defined',
    '_paq is not defined',
    "Can't find variable: fbq",
  ],
})
