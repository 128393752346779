import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import { CSSTransition } from 'react-transition-group'
import CLOSE_MODAL_ICON from '../../assets/icons/close-blue.svg'
import useLatestRef from '../hooks/useLatestRef'
import './Modal.scss'
import { H1 } from './Typography'

const Modal = ({
  isOpen,
  onClose,
  children,
  heading,
  headingCentered = true,
  headingUnderlined = true,
  small,
  dynamicHeight,
  popupClassName,
  headingFontSize,
  ...props
}) => {
  const [t] = useTranslation()
  const modalRef = useRef()
  const onCloseRef = useLatestRef(onClose)

  useEffect(() => {
    if (isOpen) {
      const handleClickOutside = (event) => {
        if (!modalRef.current.contains(event.target)) {
          onCloseRef.current()
        }
      }

      const handleKeydown = ({ keyCode }) => {
        if (keyCode === ESCAPE_KEY_CODE) {
          onCloseRef.current()
        }
      }

      document.addEventListener('mousedown', handleClickOutside, {
        capture: true, // catch sooner than react-select removes clear button form DOM
      })
      document.addEventListener('keydown', handleKeydown)

      return () => {
        document.removeEventListener('mousedown', handleClickOutside, {
          capture: true,
        })
        document.removeEventListener('keydown', handleKeydown)
      }
    }
  }, [isOpen])

  return createPortal(
    <>
      <CSSTransition
        in={isOpen}
        timeout={200}
        classNames="common__modal-animation"
        unmountOnExit
      >
        <div className="common__modal" {...props}>
          <div
            ref={modalRef}
            className={classnames(
              'common__modal__popup',
              {
                'common__modal__popup--small': small,
                'common__modal__popup--dynamic-height': dynamicHeight,
              },
              popupClassName
            )}
          >
            {onClose && (
              <div
                onClick={onClose}
                role="button"
                className="common__modal__close-button"
              >
                <img
                  src={CLOSE_MODAL_ICON}
                  className="common__modal__close-icon"
                  role="button"
                  alt={t('COMMON:CLOSE')}
                />
              </div>
            )}
            {Boolean(heading) && (
              <ModalTitle
                text={heading}
                centered={headingCentered}
                underlined={headingUnderlined}
                fontSize={headingFontSize}
              />
            )}
            {children}
          </div>
        </div>
      </CSSTransition>
    </>,
    document.body
  )
}

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  children: PropTypes.any,
  heading: PropTypes.string,
  headingFontSize: PropTypes.oneOf(['large', 'medium', 'small']),
  headingCentered: PropTypes.bool,
  headingUnderlined: PropTypes.bool,
  small: PropTypes.bool,
  dynamicHeight: PropTypes.bool,
  popupClassName: PropTypes.string,
}

const ESCAPE_KEY_CODE = 27

const ModalTitle = ({
  text,
  centered,
  underlined = true,
  className,
  style,
  fontSize = 'large',
}) => (
  <div
    className={classnames(
      'common__modal-title',
      {
        'common__modal-title--centered': centered,
        'common__modal-title--underlined': underlined,
      },
      className
    )}
    style={style}
  >
    <H1 fontSize={fontSize} className="common__modal-title__heading">
      {text}
    </H1>
  </div>
)

ModalTitle.propTypes = {
  text: PropTypes.string,
  centered: PropTypes.bool,
  underlined: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  fontSize: PropTypes.oneOf(['large', 'medium', 'small']),
}

export default Modal
