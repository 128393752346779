// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FR2Nt6lOup10nAI2Uu9za {\n  position: relative;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n._3XeX8tPge8F8tadfYjNjDF {\n  display: flex;\n  flex: 1;\n}\n._3XeX8tPge8F8tadfYjNjDF > div {\n  flex: 1;\n}\n._3XeX8tPge8F8tadfYjNjDF button {\n  outline: none;\n}\n._3XeX8tPge8F8tadfYjNjDF label div {\n  margin: 0px;\n}\n._3XeX8tPge8F8tadfYjNjDF input {\n  padding-right: 42px;\n}\n\n._2adFQz7VL1bvbR4Ie1mGV1 {\n  border: none;\n  position: absolute;\n  bottom: 22px;\n  right: 12px;\n  width: 24px;\n  height: 24px;\n}\n\n._2Iv5f2ugl-J_jBIrUpdJdz {\n  display: flex;\n  flex: 1;\n  position: relative;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchInputContainer": "FR2Nt6lOup10nAI2Uu9za",
	"searchInput": "_3XeX8tPge8F8tadfYjNjDF",
	"icon": "_2adFQz7VL1bvbR4Ie1mGV1",
	"wrapper": "_2Iv5f2ugl-J_jBIrUpdJdz"
};
module.exports = ___CSS_LOADER_EXPORT___;
