import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { Trans, useTranslation } from 'react-i18next'
import * as Sentry from '@sentry/react'
import ContainerSpinner from '@hsl-fi/container-spinner'
import Page from '../../../common/components/Page'
import WhiteBox from '../../../common/components/WhiteBox'
import { H1, P, A } from '../../../common/components/Typography'
import { LinkButton } from '../../../common/components/Button'
import apiStore from '../../../common/stores/apiStore'
import { Redirect } from '../../routes/components/Link'
import ROUTES from '../../routes/routes'

const StrongAuthenticationSuccessScreen = () => {
  const [t] = useTranslation()
  const [hasAcceptedInvite, setHasAcceptedInvite] = useState(false)
  const [hasAcceptInviteFailed, setHasAcceptInviteFailed] = useState(false)
  const [searchParams] = useSearchParams()
  const inviteId = searchParams.get('inviteId')

  useEffect(() => {
    const acceptInvite = async () => {
      try {
        await apiStore.endpoints.invite.acceptInvite(inviteId, undefined)

        setHasAcceptedInvite(true)
      } catch (e) {
        Sentry.captureEvent({
          message: 'Self-service flow finalization failed',
          extra: {
            error: e,
          },
        })

        setHasAcceptInviteFailed(true)
      }
    }

    acceptInvite()
  }, [inviteId])

  if (hasAcceptInviteFailed) {
    return (
      <Redirect
        route={ROUTES.SELF_SERVICE.STRONG_AUTHENTICATION_ERROR}
        query={{ inviteId }}
      />
    )
  }

  return (
    <ContainerSpinner visible={!hasAcceptedInvite}>
      <Page>
        <WhiteBox>
          <H1>
            {t('SELF_SERVICE:STRONG_AUTHENTICATION_SUCCESS_SCREEN.HEADING')}
          </H1>
          <P>{t('SELF_SERVICE:STRONG_AUTHENTICATION_SUCCESS_SCREEN.BODY_1')}</P>
          <P>
            <Trans
              i18nKey="SELF_SERVICE:STRONG_AUTHENTICATION_SUCCESS_SCREEN.BODY_2"
              components={{
                a: <A target="_blank" rel="noopener noreferrer" />,
              }}
              values={{ url: t('COMMON:HSL_COMMUTER_BENEFIT_HELP_PAGE_URL') }}
            />
          </P>
          <LinkButton route={ROUTES.COMPANY_ADMIN.SELECT}>
            {t(
              'SELF_SERVICE:STRONG_AUTHENTICATION_SUCCESS_SCREEN.PROCEED_BUTTON_LABEL'
            )}
          </LinkButton>
        </WhiteBox>
      </Page>
    </ContainerSpinner>
  )
}

export default StrongAuthenticationSuccessScreen
