import React from 'react'
import PropTypes from 'prop-types'
import './Body.scss'

const Body = ({ children, style }) => (
  <div className="common__body" style={style}>
    {children}
  </div>
)

Body.propTypes = {
  children: PropTypes.node,
  style: PropTypes.object,
}

export default Body
