import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'
import './InputLabel.scss'

const InputLabel = ({
  required,
  optional,
  htmlFor,
  children,
  className,
  style,
  labelSuffix,
  ...props
}) => {
  const [t] = useTranslation()

  return (
    <label
      htmlFor={htmlFor}
      className={classnames('common__input-label', className)}
      style={style}
      {...props}
    >
      {children}
      {Boolean(required) && (
        <span className="common__input-label__required">*</span>
      )}
      {optional && (
        <small className="common__input-label__optional">
          ({t('COMMON:OPTIONAL')})
        </small>
      )}
      {labelSuffix}
    </label>
  )
}

InputLabel.propTypes = {
  required: PropTypes.bool,
  optional: PropTypes.bool,
  htmlFor: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
  style: PropTypes.object,
  labelSuffix: PropTypes.node,
}

export default InputLabel
