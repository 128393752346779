import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

const useQueryParams = (initialState = {}) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const [state, setStateInternal] = useState(() => {
    const queryParams = Object.fromEntries(searchParams)
    const combinedQueryParams = { ...initialState, ...queryParams }
    return mergeState({}, combinedQueryParams)
  })

  useEffect(() => {
    setSearchParams(new URLSearchParams(state), { replace: true })
  }, [setSearchParams, state])

  const setState = useCallback(
    (changes) => {
      setStateInternal(mergeState(state, changes))
    },
    [state, setStateInternal]
  )

  return [state, setState]
}

export default useQueryParams

const mergeState = (currentState, changes) => {
  const newState = {
    ...currentState,
    ...changes,
  }

  // Strip undefined values entirely
  Object.entries(changes)
    .filter(([, value]) => typeof value === 'undefined')
    .forEach(([key]) => {
      delete newState[key]
    })

  return newState
}
