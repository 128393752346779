import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import MessageBar from './MessageBar'
import Body from './Body'
import Header from './Header'
import Footer from './Footer'
import './AdminPage.scss'

const AdminPage = ({ children, backgroundColor, className, ...props }) => (
  <>
    <Header />
    <Body style={{ backgroundColor: backgroundColor ?? 'transparent' }}>
      <div className={classnames('common__admin-page', className)} {...props}>
        {children}
      </div>
    </Body>
    <Footer />
    <MessageBar />
  </>
)

AdminPage.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
}

export default AdminPage
