import SiteFooter from '@hsl-fi/site-footer'
import { useTranslation } from 'react-i18next'
import config from '../config'

const Footer = () => {
  const [t, i18n] = useTranslation()

  return (
    <>
      <SiteFooter
        hslFiUrl={config.HSL_HOME_URL}
        lang={i18n.language}
        onCookieButtonClick={() => {
          if (window.CookieConsent) {
            window.CookieConsent.renew()
          }
        }}
        firstColumn={[
          {
            name: t('COMMON:HSL_SITE_FOOTER.CUSTOMER_SUPPORT_LABEL'),
            url: t('COMMON:HSL_SITE_FOOTER.CUSTOMER_SUPPORT_URL'),
            selected: false,
            target: '_blank',
          },
          {
            name: t('COMMON:HSL_SITE_FOOTER.INSTRUCTIONS_LABEL'),
            url: t('COMMON:HSL_SITE_FOOTER.INSTRUCTIONS_URL'),
            selected: false,
            target: '_blank',
          },
        ]}
      />
    </>
  )
}

export default Footer
