import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import QUESTION_ICON from '../../assets/icons/question.svg'
import './HelpButton.scss'

const HelpButton = ({ onClick, className, style, alt, ...props }) => (
  <button
    type="button"
    onClick={onClick}
    className={classnames('common__help-button', className)}
    style={style}
    {...props}
  >
    <img src={QUESTION_ICON} alt={alt} />
  </button>
)

HelpButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  alt: PropTypes.string,
}

export default HelpButton
