import { useMutation } from '@tanstack/react-query'
import apiStore from '../../../../../common/stores/apiStore'
import { useQueryClient } from '@tanstack/react-query'
import benefitsQueryKeys from '../common/benefitsQueryKeys'

const useSetBenefitSettingsMutation = ({ companyId }) => {
  const client = useQueryClient()
  return useMutation({
    mutationFn: async ({ payload }) => {
      return await apiStore.endpoints.company.setBalanceStatus(
        companyId,
        payload
      )
    },
    onSuccess: () =>
      client.invalidateQueries({
        queryKey: benefitsQueryKeys.settings(companyId),
      }),
  })
}

export default useSetBenefitSettingsMutation
