const env = process.env.REACT_APP_ENV

// default and production
let config = {
  HSL_HOME_URL: 'https://www.hsl.fi',
  HSL_CONTENT_URL: 'https://content.hsl.fi',
}

// compare to string values instead of imported constants to properly tree-shake unused configs
if (env === 'local' || env === 'dev') {
  config = {
    HSL_HOME_URL: 'https://dev.hslfi.hsldev.com',
    HSL_CONTENT_URL: 'https://dev-api.hslfi.hsldev.com',
  }
}
if (env === 'uat') {
  config = {
    HSL_HOME_URL: 'https://qa.hslfi.hsldev.com',
    HSL_CONTENT_URL: 'https://qa-api.hslfi.hsldev.com',
  }
}

export default config
