import { useQuery } from '@tanstack/react-query'
import apiStore from '../../../../../common/stores/apiStore'
import benefitsQueryKeys from '../common/benefitsQueryKeys'

const useBenefitSettingsQuery = ({ companyId }) => {
  return useQuery({
    queryKey: benefitsQueryKeys.settings(companyId),
    queryFn: async () => {
      return await apiStore.endpoints.company.getBalanceStatus(companyId)
    },
  })
}

export default useBenefitSettingsQuery
