import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import WhiteBox from '../../../common/components/WhiteBox'
import { H1, H2, P, A } from '../../../common/components/Typography'
import Hr from '../../../common/components/Hr'
import { formatMoney, formatMoneyPeriod } from '../../../common/format'
import { formatValidityDates } from '../../company/helpers'
import { LinkTextButton } from '../../../common/components/Button'
import TravelZone from '../../../common/components/TravelZone'
import {
  AppStoreLink,
  GooglePlayLink,
} from '../../../common/components/AppStoreLink'
import {
  BENEFIT_TYPE_SEASON_TICKET,
  BENEFIT_TYPE_ANNUAL_BALANCE,
  BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE,
} from '../../../common/constants'
import './BeneficiarySuccess.scss'
import {
  BALANCE_INTERVAL_MONTHLY,
  FIRST_DAY_OF_MONTH,
} from '../../company/components/Benefits/BenefitSettings'

const BeneficiarySuccess = ({ benefitType, benefit, zone }) => {
  const [t, i18n] = useTranslation()
  const [isHelpTextShown, setIsHelpTextShown] = useState(false)

  const isAnnualBalance = [
    BENEFIT_TYPE_ANNUAL_BALANCE,
    BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE,
  ].includes(benefitType)

  const isBusinessTrip =
    benefitType === BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE

  const isMonthlyBalance =
    benefit?.annualBalanceStatusType !== null &&
    benefit?.annualBalanceStatusType.grantPeriod === BALANCE_INTERVAL_MONTHLY
      ? true
      : false

  return (
    <WhiteBox style={{ marginBottom: '30px' }}>
      <H1>
        {isBusinessTrip
          ? t('BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.BUSINESS_TRIP.TITLE')
          : t('BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.COMMUTER_BENEFIT.TITLE')}
      </H1>
      {isAnnualBalance ? (
        <>
          <AnnualBalanceRow
            label={t(
              'BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.AMOUNT_OF_ANNUAL_BALANCE'
            )}
            value={
              isMonthlyBalance
                ? formatMoneyPeriod(benefit?.grantedAmount, i18n.language)
                : formatMoney(benefit?.grantedAmount, i18n.language)
            }
          />
          <AnnualBalanceRow
            label={t('BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.VALIDITY')}
            value={formatValidityDates(
              benefit?.validFrom,
              benefit?.validTo,
              t,
              true
            )}
          />
          {isMonthlyBalance && (
            <AnnualBalanceRow
              label={t(
                benefit?.annualBalanceStatusType.grantDay === FIRST_DAY_OF_MONTH
                  ? 'BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.FIRST_DAY_OF_THE_MONTH'
                  : 'BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.LAST_DAY_OF_THE_MONTH'
              )}
            />
          )}
          <P>
            {isBusinessTrip
              ? t(
                  'BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.BUSINESS_TRIP.ANNUAL_BALANCE_DESCRIPTION'
                )
              : t(
                  'BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.COMMUTER_BENEFIT.ANNUAL_BALANCE_DESCRIPTION'
                )}
          </P>
          {isBusinessTrip && (
            <P>
              {t(
                'BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.BUSINESS_TRIP.ABOUT_BUSINESS_TRIP_USAGE'
              )}
            </P>
          )}
        </>
      ) : (
        <>
          <P style={{ marginBottom: 0 }}>
            {t('BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.GRANTED_ZONE')}
          </P>
          <TravelZone zone={zone} style={{ marginTop: '20px' }} />
          {isHelpTextShown ? (
            <>
              <P style={{ marginTop: '20px' }}>
                {t(
                  'BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.CONTACT_SUPPORT_IF_INCORRECT_ZONE'
                )}
              </P>
              <P style={{ marginBottom: 0 }}>
                {t(
                  'BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.DO_NOT_USE_MOBILE_APP_IF_INCORRECT_ZONE'
                )}
              </P>
            </>
          ) : (
            <P style={{ marginBottom: 0 }}>
              <LinkTextButton onClick={() => setIsHelpTextShown(true)}>
                {t('BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.SHOW_HELP_TEXT')}
              </LinkTextButton>
            </P>
          )}
        </>
      )}
      <Hr style={{ marginTop: '30px', marginBottom: '30px' }} />
      <H2>
        {isBusinessTrip
          ? t('BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.BUSINESS_TRIP.HOW_TO_USE')
          : t(
              'BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.COMMUTER_BENEFIT.HOW_TO_USE'
            )}
      </H2>
      <P style={{ marginBottom: '15px' }}>
        1. {t('BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.DOWNLOAD_MOBILE_APP')}
      </P>
      <div>
        <AppStoreLink style={{ marginRight: 5 }} />
        <GooglePlayLink />
      </div>
      <P>
        2. {t('BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.SIGN_IN_TO_MOBILE_APP')}
      </P>
      <P>
        3.{' '}
        {isBusinessTrip
          ? t(
              'BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.BUSINESS_TRIP.PURCHASE_TICKET'
            )
          : t(
              isAnnualBalance
                ? 'BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.ANNUAL_BALANCE.PURCHASE_TICKET'
                : 'BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.COMMUTER_BENEFIT.PURCHASE_TICKET'
            )}
      </P>
      <Hr style={{ marginTop: '28px', marginBottom: '28px' }} />
      <P style={{ marginBottom: 0 }}>
        <Trans
          i18nKey={
            isBusinessTrip
              ? 'BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.BUSINESS_TRIP.MORE_HELP'
              : 'BENEFICIARY:BENEFICIARY_SUCCESS_SCREEN.COMMUTER_BENEFIT.MORE_HELP'
          }
          components={{
            a: <A target="_blank" rel="noopener noreferrer" />,
          }}
          values={{ url: t('COMMON:HSL_COMMUTER_BENEFIT_HELP_PAGE_URL') }}
        />
      </P>
    </WhiteBox>
  )
}

BeneficiarySuccess.propTypes = {
  benefitType: PropTypes.oneOf([
    BENEFIT_TYPE_SEASON_TICKET,
    BENEFIT_TYPE_ANNUAL_BALANCE,
    BENEFIT_TYPE_BUSINESS_TRIP_ANNUAL_BALANCE,
  ]).isRequired,
  benefit: PropTypes.object,
  zone: PropTypes.string,
}

const AnnualBalanceRow = ({ label, value, style }) => (
  <div
    className="beneficiary__beneficiary-success__annual-balance-row"
    style={style}
  >
    <div className="beneficiary__beneficiary-success__annual-balance-row__label">
      {label}
    </div>
    <div className="beneficiary__beneficiary-success__annual-balance-row__value">
      {value}
    </div>
  </div>
)

AnnualBalanceRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
}

export default BeneficiarySuccess
