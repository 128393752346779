import { useRef } from 'react'

/**
 * Hook that always keeps reference updated to latest value
 */
const useLatestRef = (value) => {
  const ref = useRef(value)
  ref.current = value
  return ref
}

export default useLatestRef
