const companiesQueryKeys = {
  root: ['companies'],
  single: (companyId) => [...companiesQueryKeys.root, 'company', companyId],
  companiesList: (options) => [...companiesQueryKeys.root, 'list', options],
  tags: (companyId, options) => [
    ...companiesQueryKeys.single(companyId),
    'tags',
    options,
  ],
}

export default companiesQueryKeys
