import { useMemo } from 'react'
import { DEFAULT_LANGUAGE } from './constants'

export const sleep = async (durationMs) =>
  new Promise((resolve) => window.setTimeout(resolve, durationMs))

// A case-insensitive comparison function that takes a resolver function
// and correctly handles Finnish/Swedish characters.
//
// Example:
//
// [
//   { city: 'Örebro' },
//   { city: 'Åmål' },
//   { city: 'Asker' },
//   { city: 'Skellefteå' },
// ]
//   .sort(sortBy($ => $.name))
export const sortBy = (resolve) => (a, b) => {
  const valueA = String(resolve(a))
  const valueB = String(resolve(b))

  return valueA.localeCompare(valueB, DEFAULT_LANGUAGE.code)
}

// Declares a value once and keeps it cached and unchanged for the entire
// lifecycle of the consumer component.
export const useImmutable = (value) => {
  return useMemo(() => value, [])
}

// Clamps a number between the given lower and upper values.
export const clamp = (number, lower, upper) => {
  if (!upper) {
    return Math.max(number, lower) === lower ? number : lower
  } else if (Math.min(number, lower) === number) {
    return lower
  } else if (Math.max(number, upper) === number) {
    return upper
  }

  return number
}

// A debouncer that runs the given function at most once per wait cycle. Optionally,
// takes an "immediate" parameter if the function is to be run once right away.
export const debounce = (fn, wait, immediate = false) => {
  let timeout

  return (...args) => {
    window.clearTimeout(timeout)

    timeout = window.setTimeout(() => {
      timeout = undefined

      if (!immediate) {
        fn(...args)
      }
    }, wait)

    if (immediate && !timeout) {
      fn(...args)
    }
  }
}

// Creates an array containing the given number (count) of items. Takes an
// optional mapper function for specifying the item structure.
export const times = (count, mapper = (item) => item) =>
  [...Array(count).keys()].map(mapper)

// Returns a new array containing unique items based on the predicate.
export const uniqBy = (arr, predicate) => {
  const cb = typeof predicate === 'function' ? predicate : (o) => o[predicate]

  return [
    ...arr
      .reduce((map, item) => {
        const key = item === null || item === undefined ? item : cb(item)

        map.has(key) || map.set(key, item)

        return map
      }, new Map())
      .values(),
  ]
}

export const toBoolean = (value) =>
  typeof value === 'string' ? value === 'true' : value

export const isValidDate = (value) => {
  if (Object.prototype.toString.call(value) === '[object Date]') {
    if (isNaN(value)) {
      return false
    }

    return true
  }

  return false
}
