// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@keyframes _38RLtrmrP9HcFD6E_0UZlP {\n  from {\n    opacity: 0;\n  }\n  to {\n    opacity: 1;\n  }\n}\n\n@keyframes yEwQBLTgFFZFCA1PsJig- {\n  from {\n    opacity: 1;\n  }\n  to {\n    opacity: 0;\n  }\n}\n\n@keyframes _2rtekbTs_98iVsxEDvyVpd {\n  0% {\n    opacity: 0;\n    transform: translateY(20px) scale(0.95);\n  }\n  100% {\n    opacity: 1;\n    transform: translateY(0) scale(1);\n  }\n}\n\n._1MSxO77oMhfOPnM8i5RH4f {\n  margin-top: 10px;\n  color: #c90036;\n  opacity: 0;\n}\n@media screen and (min-width: 1px) {\n  ._1MSxO77oMhfOPnM8i5RH4f {\n    font-size: 14px;\n    letter-spacing: -0.3px;\n    line-height: 1.5;\n  }\n}\n@media screen and (min-width: 320px) {\n  ._1MSxO77oMhfOPnM8i5RH4f {\n    font-size: 14px;\n    letter-spacing: -0.3px;\n    line-height: 1.5;\n  }\n}\n@media screen and (min-width: 375px) {\n  ._1MSxO77oMhfOPnM8i5RH4f {\n    font-size: 14px;\n    letter-spacing: -0.3px;\n    line-height: 1.5;\n  }\n}\n@media screen and (min-width: 768px) {\n  ._1MSxO77oMhfOPnM8i5RH4f {\n    font-size: 14px;\n    letter-spacing: -0.3px;\n    line-height: 1.5;\n  }\n}\n@media screen and (min-width: 920px) {\n  ._1MSxO77oMhfOPnM8i5RH4f {\n    font-size: 14px;\n    letter-spacing: -0.3px;\n    line-height: 1.5;\n  }\n}\n@media screen and (min-width: 1024px) {\n  ._1MSxO77oMhfOPnM8i5RH4f {\n    font-size: 14px;\n    letter-spacing: -0.47px;\n    line-height: 1.29;\n  }\n}\n@media screen and (min-width: 1260px) {\n  ._1MSxO77oMhfOPnM8i5RH4f {\n    font-size: 14px;\n    letter-spacing: -0.47px;\n    line-height: 1.29;\n  }\n}\n@media screen and (min-width: 1440px) {\n  ._1MSxO77oMhfOPnM8i5RH4f {\n    font-size: 14px;\n    letter-spacing: -0.47px;\n    line-height: 1.29;\n  }\n}\n.aB5APzdh3Od1CZhcPRe-B {\n  animation: _38RLtrmrP9HcFD6E_0UZlP 0.2s;\n  animation-delay: 0.2s;\n  animation-fill-mode: forwards;\n}\n._32NFQMvVPQcF3DyNKbZu3- {\n  animation: yEwQBLTgFFZFCA1PsJig- 0.2s;\n}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textInputError": "_1MSxO77oMhfOPnM8i5RH4f",
	"textInputErrorFadeIn": "aB5APzdh3Od1CZhcPRe-B",
	"fadeIn": "_38RLtrmrP9HcFD6E_0UZlP",
	"textInputErrorFadeOut": "_32NFQMvVPQcF3DyNKbZu3-",
	"fadeOut": "yEwQBLTgFFZFCA1PsJig-",
	"fadeInUp": "_2rtekbTs_98iVsxEDvyVpd"
};
module.exports = ___CSS_LOADER_EXPORT___;
