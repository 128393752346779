import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import uiMessageStore from '../../../../common/stores/uiMessageStore'

export class CustomMessageQueryError extends Error {
  constructor(messageKey, messageOptions) {
    super(messageKey)
    this.messageOptions = messageOptions
  }
}

/**
 * Custom QueryClient provider that shows errors as global UI message
 */
const CustomQueryClientProvider = (props) => {
  const [t] = useTranslation()

  const client = useMemo(() => {
    const onError = (error) => {
      if (error instanceof CustomMessageQueryError) {
        uiMessageStore.addError(t(error.message, error.messageOptions))
      } else {
        uiMessageStore.addError(t('COMMON:ERRORS.SERVER_ERROR'))
      }
      // eslint-disable-next-line no-console
      console.error(error)
    }

    return new QueryClient({
      queryCache: new QueryCache({ onError }),
      mutationCache: new MutationCache({ onError }),
    })
  }, [t])

  return <QueryClientProvider client={client} {...props} />
}

export default CustomQueryClientProvider
