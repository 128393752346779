import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useTranslation } from 'react-i18next'
import { v4 as uuid } from 'uuid'
import { useImmutable } from '../utility'
import InputLabel from './InputLabel'
import './TextInput.scss'
import InputError from './InputError'

const TextInput = forwardRef(
  (
    {
      type = 'text',
      label,
      labelSuffix,
      value,
      error,
      hasError = typeof error === 'string',
      required,
      placeholder,
      id,
      prepend,
      prependAlt,
      small,
      max,
      className,
      style,
      inputClassName,
      inputStyle,
      labelStyle,
      labelClassName,
      ariaLabel,
      disabled,
      optional,
      fitContent,
      ...props
    },
    ref
  ) => {
    const inputId = id || useImmutable(`TextInput__${uuid()}`)
    const [t] = useTranslation()
    const charactersRemaining = max - (value?.length ?? 0)
    const hasHeader = Boolean(label) || Boolean(max)

    return (
      <div
        className={classnames('common__text-input', className)}
        style={style}
      >
        {hasHeader && (
          <div className="common__text-input__header">
            {Boolean(label) && (
              <InputLabel
                required={required}
                htmlFor={inputId}
                className={labelClassName}
                style={labelStyle}
                optional={optional}
                labelSuffix={labelSuffix}
              >
                {label}
              </InputLabel>
            )}
            {Boolean(max) && (
              <div
                className={classnames(
                  'common__text-input__characters-remaining',
                  {
                    'common__text-input__characters-remaining--exceeded':
                      charactersRemaining < 0,
                  }
                )}
              >
                {t('COMMON:X_CHARACTERS_REMAINING', {
                  remaining: charactersRemaining,
                  max,
                })}
              </div>
            )}
          </div>
        )}
        <div className="common__text-input__container">
          {Boolean(prepend) && (
            <div className="common__text-input__prepend">
              <img
                src={prepend}
                className="common__text-input__prepend-image"
                alt={prependAlt}
              />
            </div>
          )}
          <input
            ref={ref}
            id={inputId}
            type={type}
            value={value}
            className={classnames(
              'common__text-input__input',
              {
                'common__text-input__input--error': hasError,
                'common__text-input__input--small': small,
                'common__text-input__input--has-prepend': Boolean(prepend),
                'common__text-input__input--disabled': disabled,
                'common__text-input__input--fit-content': fitContent,
              },
              inputClassName
            )}
            style={inputStyle}
            required={required}
            placeholder={placeholder}
            aria-label={ariaLabel || label || placeholder}
            aria-required={required}
            disabled={disabled}
            {...props}
          />
        </div>
        <InputError>{error}</InputError>
      </div>
    )
  }
)

TextInput.displayName = 'TextInput'

TextInput.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  labelSuffix: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.string,
  hasError: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  prepend: PropTypes.string,
  prependAlt: PropTypes.string,
  small: PropTypes.bool,
  max: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  inputClassName: PropTypes.string,
  inputStyle: PropTypes.object,
  labelClassName: PropTypes.string,
  labelStyle: PropTypes.object,
  ariaLabel: PropTypes.string,
  disabled: PropTypes.bool,
  optional: PropTypes.bool,
  fitContent: PropTypes.bool,
}

export default TextInput
