import React, { useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { H3, P, Ul, B } from '../../../../common/components/Typography'
import DecimalInput from '../../../../common/components/DecimalInput'
import PaddedContent from '../../../../common/components/PaddedContent'
import InputLabel from '../../../../common/components/InputLabel'
import { RadioButtonWithLabel } from '../../../../common/components/RadioGroup'
import Hr from '../../../../common/components/Hr'
import HelpButton from '../../../../common/components/HelpButton'
import Modal from '../../../../common/components/Modal'
import { Button, ButtonGroup } from '../../../../common/components/Button'
import { validateMoney } from '../../../../common/validate'
import { SEASON_TICKET_PRICE } from '../../../../common/constants'
import './BenefitSettings.scss'
import { formatMoney } from '../../../../common/format'
import ContainerSpinner from '@hsl-fi/container-spinner'
import useOnChange from '../../../../common/hooks/useOnChange'
import useCompanyId from '../../../../common/hooks/useCompanyId'
import useSetBenefitSettingsMutation from './queries/useSetBenefitSettingsMutation'
import useBenefitSettingsQuery from './queries/useBenefitSettingsQuery'
import uiMessageStore from '../../../../common/stores/uiMessageStore'

export const BALANCE_INTERVAL_YEARLY = 'YEAR'
export const BALANCE_INTERVAL_MONTHLY = 'MONTH'

export const FIRST_DAY_OF_MONTH = 'FIRST'

export const UI_STATE_HELP_MODAL_OPEN = 'helpModalOpen'
export const BALANCE_RENEWAL_SAME_AS_PREVIOUS_YEAR = 'SAME_AS_LAST'

export const BALANCE_RENEWAL_NOT_SET = 'notSet'
export const BALANCE_RENEWAL_NEW_BALANCE = 'CUSTOM_COMPANY_WIDE'

const initialForm = {
  grantPeriod: BALANCE_INTERVAL_YEARLY,
  grantDay: FIRST_DAY_OF_MONTH,
  resetAnnualBalance: true,
  resetType: 'CUSTOM_COMPANY_WIDE',
  resetGrantedAmount: 0,
  updateCurrentGranted: null,
}

const BenefitSettings = () => {
  const { t } = useTranslation()
  const [form, setForm] = useState({})
  const [errors, setErrors] = useState({})
  const [uiState, setUiState] = useState(undefined)
  const [saldoModalOpen, setSaldoModalOpen] = useState(false)
  const [isEditingSaldoModal, setIsEditingSaldoModal] = useState(false)
  const [modalSaldoInputValue, setModalSaldoInputValue] = useState(undefined)
  const companyId = useCompanyId()
  const [grantPeriodChanged, setGrantPeriodChanged] = useState(false)

  const { data: benefitSettingsData, isLoading: isBenefitSettingsDataLoading } =
    useBenefitSettingsQuery({
      companyId,
    })

  const { mutateAsync: setBenefitSettings } = useSetBenefitSettingsMutation({
    companyId,
  })

  const openModalIfChangingGrantPeriod = (value) => {
    if (value !== form.grantPeriod) {
      setSaldoModalOpen(true)
      setGrantPeriodChanged(true)
    }
  }

  const submit = async () => {
    const payload = { ...form }
    // The BALANCE_RENEWAL_NOT_SET is a special case where the backend expects
    // the same payload as setting a custom saldo that is 0
    if (form.resetType === BALANCE_RENEWAL_NOT_SET) {
      payload.resetType = BALANCE_RENEWAL_NEW_BALANCE
      payload.resetGrantedAmount = 0
    }
    if (payload.grantPeriod == BALANCE_INTERVAL_YEARLY) payload.grantDay = null
    if (!grantPeriodChanged) payload.updateCurrentGranted = null
    setGrantPeriodChanged(false)

    await setBenefitSettings({ payload })
    uiMessageStore.addMessage(t('COMMON:SAVE_SUCCESS'))
  }

  useOnChange(() => {
    if (!benefitSettingsData) {
      setForm(initialForm)
      return
    }
    setForm({
      grantDay: benefitSettingsData.grantDay,
      grantPeriod: benefitSettingsData.grantPeriod,
      resetAnnualBalance: benefitSettingsData.resetAnnualBalance,
      resetGrantedAmount: benefitSettingsData.resetGrantedAmount,
      resetType: benefitSettingsData.resetType,
    })
  }, benefitSettingsData)

  const cancelGrantPeriodChange = () => {
    if (isEditingSaldoModal) {
      setIsEditingSaldoModal(false)
      setSaldoModalOpen(false)
      return
    }
    const grantPeriod =
      form.grantPeriod === BALANCE_INTERVAL_YEARLY
        ? BALANCE_INTERVAL_MONTHLY
        : BALANCE_INTERVAL_YEARLY
    setForm({
      ...form,
      grantPeriod,
      grantDay:
        grantPeriod === BALANCE_INTERVAL_MONTHLY ? FIRST_DAY_OF_MONTH : null,
    })
    setSaldoModalOpen(false)
  }

  if (isBenefitSettingsDataLoading) {
    return <ContainerSpinner visible />
  }

  return (
    <>
      <Modal
        isOpen={saldoModalOpen}
        dynamicHeight
        onClose={() => cancelGrantPeriodChange()}
      >
        <form
          onSubmit={(event) => {
            event.preventDefault()
            setForm({ ...form, updateCurrentGranted: modalSaldoInputValue })
            setSaldoModalOpen(false)
          }}
        >
          <H3 fontSize="medium" style={{ marginTop: 0 }}>
            {t(
              'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_RENEWAL_CHANGE_SALDO_TYPE_HEADING'
            )}
          </H3>
          <P>
            <Trans
              i18nKey={
                form.grantPeriod === BALANCE_INTERVAL_YEARLY
                  ? 'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_RENEWAL_CHANGE_SALDO_TYPE_BODY_YEARLY'
                  : 'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_RENEWAL_CHANGE_SALDO_TYPE_BODY_MONTHLY'
              }
              components={{ b: <B /> }}
            />
          </P>
          <div className="company__benefit-settings__balance-input-container">
            <DecimalInput
              label={t('COMPANY:INVITATION_FORM.BALANCE_AMOUNT')}
              defaultValue={modalSaldoInputValue}
              onChange={(value) => setModalSaldoInputValue(value)}
              hasError={Boolean(errors.updateCurrentGranted)}
              required
              style={{
                marginTop: '10px',
                marginBottom: '10px',
              }}
            />
            <span className="company__benefit-settings__balance-currency">
              {form.grantPeriod === BALANCE_INTERVAL_YEARLY
                ? '€'
                : t('COMMON:CURRENCY_MONTH')}
            </span>
          </div>
          <P style={{ color: 'red' }}>
            {modalSaldoInputValue > SEASON_TICKET_PRICE &&
            form.grantPeriod === BALANCE_INTERVAL_MONTHLY ? (
              t('COMPANY:BENEFIT_SETTINGS_SCREEN.DID_YOU_KNOW_BALANCE_SALDO')
            ) : (
              <>
                <br />
                <br />
              </>
            )}
          </P>
          <ButtonGroup>
            <Button
              type="submit"
              disabled={
                modalSaldoInputValue === 0 || modalSaldoInputValue === ''
              }
            >
              {t('COMPANY:BENEFIT_SETTINGS_SCREEN.CONFIRM')}
            </Button>
            <Button secondary onClick={() => cancelGrantPeriodChange()}>
              {t('COMPANY:BENEFIT_SETTINGS_SCREEN.CANCEL')}
            </Button>
          </ButtonGroup>
        </form>
      </Modal>
      <form
        onSubmit={(event) => {
          event.preventDefault()
          const validated = validate(form, t)
          setErrors(validated)
          if (Object.keys(errors).length > 0) {
            return
          }
          submit()
        }}
      >
        <PaddedContent>
          <H3 fontSize="medium" style={{ marginTop: 0 }}>
            {t('COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE')}
          </H3>
          <P>{t('COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_BODY_1')}</P>
          <P>{t('COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_BODY_2')}</P>
          <InputLabel>
            {t('COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_INTERVAL')}
          </InputLabel>
          <RadioButtonWithLabel
            label={t('COMPANY:BENEFIT_SETTINGS_SCREEN.YEARLY')}
            checked={form.grantPeriod === BALANCE_INTERVAL_YEARLY}
            onCheck={() => {
              setForm({
                ...form,
                grantPeriod: BALANCE_INTERVAL_YEARLY,
                grantDay: null,
              })
              openModalIfChangingGrantPeriod(BALANCE_INTERVAL_YEARLY)
            }}
          />
          <RadioButtonWithLabel
            label={t('COMPANY:BENEFIT_SETTINGS_SCREEN.MONTHLY')}
            checked={form.grantPeriod === BALANCE_INTERVAL_MONTHLY}
            onCheck={() => {
              setForm({
                ...form,
                grantPeriod: BALANCE_INTERVAL_MONTHLY,
                grantDay: FIRST_DAY_OF_MONTH,
              })
              openModalIfChangingGrantPeriod(BALANCE_INTERVAL_MONTHLY)
            }}
            style={{ marginBottom: '20px' }}
          />
          {form.updateCurrentGranted ? (
            <P>
              <span style={{ marginRight: '10px' }}>
                <Trans
                  i18nKey="COMPANY:BENEFIT_SETTINGS_SCREEN.GRANTED"
                  values={{
                    value: formatMoney(form.updateCurrentGranted),
                  }}
                  components={{ b: <B /> }}
                />
              </span>
              <Button
                bold
                style={{ marginLeft: '20px' }}
                transparent
                compact
                onClick={() => {
                  setSaldoModalOpen(true)
                  setIsEditingSaldoModal(true)
                }}
              >
                {t('COMPANY:BENEFIT_SETTINGS_SCREEN.EDIT')}
              </Button>
            </P>
          ) : null}
        </PaddedContent>
        <Hr style={{ marginTop: '35px', marginBottom: '30px' }} />
        <PaddedContent>
          <div className="company__benefit-settings__heading-container">
            <H3 fontSize="medium" style={{ marginTop: 0, marginBottom: 0 }}>
              {t(
                'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_SETTINGS_AT_START_OF_YEAR'
              )}
            </H3>
            <HelpButton
              onClick={() => setUiState(UI_STATE_HELP_MODAL_OPEN)}
              style={{ marginTop: '3px', marginLeft: '10px' }}
            />
            <Modal
              heading={t(
                'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_SETTINGS_AT_START_OF_YEAR'
              )}
              isOpen={uiState === UI_STATE_HELP_MODAL_OPEN}
              onClose={() => setUiState(undefined)}
              dynamicHeight
            >
              <Ul
                items={[
                  t(
                    'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_SETTINGS_AT_START_OF_YEAR_HELP_MODAL_BODY_1'
                  ),
                  t(
                    'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_SETTINGS_AT_START_OF_YEAR_HELP_MODAL_BODY_2'
                  ),
                  t(
                    'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_SETTINGS_AT_START_OF_YEAR_HELP_MODAL_BODY_3'
                  ),
                  t(
                    'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_SETTINGS_AT_START_OF_YEAR_HELP_MODAL_BODY_4'
                  ),
                ]}
                compact
                fontSize="small"
              />
            </Modal>
          </div>
          <RadioButtonWithLabel
            label={t(
              'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_RESETS_AT_START_OF_YEAR'
            )}
            checked={form.resetAnnualBalance}
            onCheck={() =>
              setForm({
                ...form,
                resetAnnualBalance: true,
                resetType: BALANCE_RENEWAL_SAME_AS_PREVIOUS_YEAR,
              })
            }
          />
          <RadioButtonWithLabel
            label={t(
              'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_DOES_NOT_RESET_AT_START_OF_YEAR'
            )}
            checked={!form.resetAnnualBalance}
            onCheck={() =>
              setForm({
                ...form,
                resetAnnualBalance: false,
                resetType: null,
                resetGrantedAmount: undefined,
              })
            }
          />
          {form.resetAnnualBalance && (
            <div>
              <InputLabel style={{ marginTop: '30px' }}>
                {t('COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_RENEWAL_SETTINGS')}
              </InputLabel>
              <RadioButtonWithLabel
                label={t(
                  'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_RENEWAL_SAME_AS_PREVIOUS_YEAR'
                )}
                checked={
                  form.resetType === BALANCE_RENEWAL_SAME_AS_PREVIOUS_YEAR
                }
                onCheck={() =>
                  setForm({
                    ...form,
                    resetType: BALANCE_RENEWAL_SAME_AS_PREVIOUS_YEAR,
                    resetGrantedAmount: undefined,
                  })
                }
                hasError={Boolean(errors.resetType)}
              />
              <RadioButtonWithLabel
                label={t(
                  'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_RENEWAL_NEW_BALANCE'
                )}
                checked={form.resetType === BALANCE_RENEWAL_NEW_BALANCE}
                onCheck={() =>
                  setForm({
                    ...form,
                    resetType: BALANCE_RENEWAL_NEW_BALANCE,
                  })
                }
                hasError={Boolean(errors.resetType)}
                style={{
                  marginBottom: 0,
                }}
              />
              {form.resetType === BALANCE_RENEWAL_NEW_BALANCE && (
                <div
                  className="company__benefit-settings__balance-input-container"
                  style={{ marginLeft: '40px' }}
                >
                  <DecimalInput
                    label={t(
                      'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_RENEWAL_NEW_BALANCE_FROM_START_OF_YEAR'
                    )}
                    defaultValue={form.resetGrantedAmount}
                    onChange={(value) =>
                      setForm({ ...form, resetGrantedAmount: value })
                    }
                    hasError={Boolean(errors.resetGrantedAmount)}
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                    }}
                  />
                  <span className="company__benefit-settings__balance-currency">
                    €
                  </span>
                </div>
              )}
            </div>
          )}
          <Button type="submit" style={{ marginTop: '30px' }}>
            {t('COMMON:SAVE')}
          </Button>
        </PaddedContent>
      </form>
    </>
  )
}

const validate = (form, t) => {
  const errors = {}

  if (form.resetBalanceAtStartOfYear) {
    if (!form.resetType) {
      errors.resetType = t('COMMON:INVALID_VALUE_X', {
        field: t('COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_RENEWAL'),
      })
    }

    if (!validateMoney(form.newBalance)) {
      errors.newBalance = t('COMMON:INVALID_VALUE_X', {
        field: t(
          'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_RENEWAL_NEW_BALANCE_FROM_START_OF_YEAR'
        ),
      })
    }
  }

  if (form.resetType === BALANCE_RENEWAL_NEW_BALANCE) {
    if (!validateMoney(form.resetGrantedAmount)) {
      errors.resetGrantedAmount = t('COMMON:INVALID_VALUE_X', {
        field: t(
          'COMPANY:BENEFIT_SETTINGS_SCREEN.BALANCE_RENEWAL_NEW_BALANCE_FROM_START_OF_YEAR'
        ),
      })
    }
  }
  return errors
}

export default BenefitSettings
