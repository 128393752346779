import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './WideContent.scss'

const WideContent = ({
  children,
  withTopMargin,
  withBottomMargin,
  withVerticalPadding,
  withoutHorizontalPadding,
  transparent,
  centered,
  className,
  style,
  ...props
}) => (
  <div
    className={classnames(
      'common__wide-content',
      {
        'common__wide-content--with-top-margin': withTopMargin,
        'common__wide-content--with-bottom-margin': withBottomMargin,
        'common__wide-content--with-padding': withVerticalPadding,
        'common__wide-content--without-horizontal-padding':
          withoutHorizontalPadding,
        'common__wide-content--transparent': transparent,
        'common__wide-content--centered': centered,
      },
      className
    )}
    style={style}
    {...props}
  >
    {children}
  </div>
)

WideContent.propTypes = {
  children: PropTypes.any,
  withTopMargin: PropTypes.bool,
  withBottomMargin: PropTypes.bool,
  withVerticalPadding: PropTypes.bool,
  withoutHorizontalPadding: PropTypes.bool,
  transparent: PropTypes.bool,
  centered: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
}

export default WideContent
